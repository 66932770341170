import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import Amplify, { Auth } from "aws-amplify";
import { LoaderService } from "src/app/core/loading/loader.service";
import { NotificationsService } from "src/app/core/notifications/notifications.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup;
  errors: { [key: string]: string[] };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private notifications: NotificationsService,
    private loaderService: LoaderService
  ) {
    Amplify.configure({
      aws_cognito_region: environment.cognitoRegion,
      aws_user_pools_id: environment.cognitoUserPoolId,
      aws_user_pools_web_client_id: environment.cognitoClientId,
      Analytics: {
        disabled: true,
      },
    });
  }

  ngOnInit(): void {
    this.resetForm = this.fb.group({
      userName: [null, Validators.compose([Validators.required])],
    });
  }
  submit() {
    this.loaderService.isLoading.next(true);
    Auth.forgotPassword(this.formControls.userName.value)
      .then((data) => {
        this.loaderService.isLoading.next(false);
        this.notifications.success(
          "Reset Pin",
          "An email was sent with a code to reset the pin"
        );

        this.router.navigate([
          `/auth/recover/${this.formControls.userName.value}`,
        ]);
      })
      .catch((error) => {
        this.loaderService.isLoading.next(false);
        this.notifications.error("Error", "Recovery email could not be sent");
      });
  }
  public get formControls(): any {
    return this.resetForm.controls;
  }
  validUser() {
    if (!this.formControls.userName.value.includes("@")) {
      if (this.formControls.userName.value.length !== 16) return false;
    }

    return true;
  }
}
