export class User {
  firstName: string;
  lastName: string;
  language: string;
  token: string;
  refreshToken: string;
  expiresTokenAt: Date;
  secondsRefreshToken: number;
  pan: string;
  email?: string;
  phoneNumber?: string;
}
