import { Component, OnInit } from "@angular/core";
import { List } from "src/app/core/models/list";
import { AuthenticationService } from "src/app/core/services/authentication.service";
import { UsersService } from "src/app/routes/users/users.service";
import { UserblockService } from "./userblock.service";

@Component({
  selector: "app-userblock",
  templateUrl: "./userblock.component.html",
  styleUrls: ["./userblock.component.scss"],
})
export class UserblockComponent implements OnInit {
  user: any;
  userRoles: List[];
  constructor(
    public userblockService: UserblockService,
    private authService: AuthenticationService,
    private userService: UsersService
  ) {
    const authenticatedUser = this.authService.currentUser();
    this.user = {
      firstName: authenticatedUser ? authenticatedUser.firstName : "",
      lastName: authenticatedUser ? authenticatedUser.lastName : "",
      pan: authenticatedUser ? authenticatedUser.pan : "",
    };
  }

  ngOnInit(): void {
    this.userRoles = this.userService.getUserRoles();
  }

  userBlockIsVisible(): any {
    return this.userblockService.getVisibility();
  }

  findUserRoleDescription(userId: string): string {
    return this.userRoles?.find((x) => x.Id === userId)?.Value
      ? this.userRoles.find((x) => x.Id === userId).Value
      : "-";
  }
}
