import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ChangeParticipantPinCommand } from "src/app/dtos/changeParticipantPinCommand";
import { ChangeParticipantPinResponse } from "src/app/dtos/changeParticipantPinResponse";
import { ParticipantAccountDTO } from "src/app/dtos/participantAccountDTO";
import { ParticipantBalanceDTO } from "src/app/dtos/participantBalanceDTO";
import { ParticipantLoginCommand } from "src/app/dtos/participantLoginCommand";
import { ParticipantLoginResponse } from "src/app/dtos/participantLoginResponse";
import { BaseService } from "./base.service";
import { SignUpCommand } from "src/app/dtos/signUpCommand";
import { SignUpResponse } from "src/app/dtos/signUpResponse";

@Injectable({
  providedIn: "root",
})
export class WalletService extends BaseService {
  constructor(protected readonly http: HttpClient) {
    super(http);
    this.setBaseUrl("Wallet");
  }
  getBalance() {
    return this.http.get<ParticipantBalanceDTO>(this.buildUrl(`/Balance`), {});
  }
  getMyAccount() {
    return this.http.get<ParticipantAccountDTO>(
      this.buildUrl(`/MyAccount`),
      {}
    );
  }
  login(dto: ParticipantLoginCommand): Observable<ParticipantLoginResponse> {
    return this.http.post<ParticipantLoginResponse>(
      this.buildUrl(`/Login`),
      dto
    );
  }

  changePin(
    dto: ChangeParticipantPinCommand
  ): Observable<ChangeParticipantPinResponse> {
    return this.http.put<ChangeParticipantPinResponse>(
      this.buildUrl(`/Pin`),
      dto
    );
  }
}
