import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { plainToClass } from "class-transformer";
import { Observable } from "rxjs";
import { User } from "../models/user";
import { SettingsService } from "../settings/settings.service";
import { environment } from "./../../../environments/environment";

@Injectable()
export class AuthenticationService {
  constructor(private http: HttpClient, private settings: SettingsService) {}

  logout(): any {
    this.clearStorage();
  }
  get isAuthenticated(): any {
    return localStorage.getItem("currentUser") != null;
  }

  clearStorage(): any {
    localStorage.removeItem("currentUser");
  }

  currentUser(): User {
    try {
      const transformed = plainToClass(
        User,
        JSON.parse(localStorage.getItem("currentUser"))
      );

      if (transformed) {
        if (Array.isArray(transformed)) {
          return transformed[0];
        }
        return transformed;
      }

      return null;
    } catch (error) {
      return null;
    }
  }

  setCurrentUser(user: User): any {
    localStorage.setItem("currentUser", JSON.stringify(user));
  }
}
