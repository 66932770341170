<div class="wrapper">
  <div class="block-center mt-4 wd-responsive">
    <!-- START card-->
    <div class="card card-flat">
      <div class="card-header text-center bg-primary">
        <img
          class="solimarket"
          src="/assets/img/solimarket-portal.png"
          alt="image"
        />
      </div>
      <p class="title">
        Sign in with your email or Card number and PIN to check your balance
      </p>

      <div class="card-body" style="padding-top: 1em !important">
        <form [formGroup]="loginForm" class="form-validate mb-3">
          <div class="form-group">
            <label class="m-0">Email or Card Number</label>

            <div class="input-group with-focus">
              <input
                class="form-control"
                autocomplete="off"
                formControlName="userName"
                required=""
              />
            </div>
            <div
              class="text-danger"
              *ngIf="
                loginForm.controls['userName'].hasError('required') &&
                (loginForm.controls['userName'].dirty ||
                  loginForm.controls['userName'].touched)
              "
            >
              This field is required
            </div>
            <div class="text-danger ml-2 mt-2">{{ errors?.pan }}</div>
            <div class="text-danger ml-2 mt-2">{{ errors?.email }}</div>
          </div>
          <div class="form-group">
            <label class="m-0">PIN</label>

            <div class="input-group with-focus">
              <input
                class="form-control pin"
                id="exampleInputPassword1"
                type="password"
                name="password"
                maxlength="4"
                formControlName="password"
                required=""
              />
            </div>
            <div
              class="text-danger"
              *ngIf="
                loginForm.controls['password'].hasError('required') &&
                (loginForm.controls['password'].dirty ||
                  loginForm.controls['password'].touched)
              "
            >
              This field is required
            </div>
            <div class="text-danger ml-2 mt-2">{{ errors?.pin }}</div>
            <div class="clearfix mt-2">
              <div class="">
                <a class="forgot-pin" [routerLink]="'/auth/reset'"
                  >Forgot your PIN?</a
                >
              </div>
            </div>
          </div>

          <button
            class="btn btn-block btn-lg btn-success mt-4 mb-4 custom-sign-in-btn"
            type="submit"
            [disabled]="!loginForm.valid || !loginForm.dirty || !validUser()"
            (click)="submit()"
          >
            Sign In
          </button>
          <hr />
          <span class="custom-reapply-span">
            New Mexico application has moved. Contact 505-469-0548
          </span>
        </form>
      </div>
    </div>
  </div>
</div>
