import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { LookupValueDTO } from "src/app/dtos/lookupValueDTO";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class LookupService extends BaseService {
  constructor(protected readonly http: HttpClient) {
    super(http);
    this.setBaseUrl("");
  }

  searchStates(filter: string): Observable<LookupValueDTO[]> {
    return this.lookupSearch("STATE", filter);
  }

  searchRegions(filter: string): Observable<LookupValueDTO[]> {
    return this.lookupSearch("REGION", filter);
  }

  searchCounties(filter: string): Observable<LookupValueDTO[]> {
    return this.lookupSearch("COUNTY", filter);
  }

  searchEthnics(filter: string): Observable<LookupValueDTO[]> {
    return this.lookupSearch("ETHNIC", filter);
  }

  searchAllEthnics(): Observable<LookupValueDTO[]> {
    return this.lookupSearchAll("ETHNIC");
  }

  searchBanks(filter: string): Observable<LookupValueDTO[]> {
    return this.lookupSearch("BANK", filter);
  }

  lookupSearch(lookupType: string, searchCriteria: string): Observable<any> {
    let params = new HttpParams();
    params = params.append("disableLoading", "true");

    return this.http
      .get<LookupValueDTO[]>(
        this.buildUrl(`Lookups/Search/${lookupType}/${searchCriteria}`),
        {
          params,
        }
      )
      .pipe(catchError(this.handleError));
  }

  lookupSearchAll(lookupType: string): Observable<any> {
    let params = new HttpParams();
    params = params.append("disableLoading", "true");

    return this.http
      .get<LookupValueDTO[]>(this.buildUrl(`Lookups/Search/${lookupType}`), {
        params,
      })
      .pipe(catchError(this.handleError));
  }

  searchStores(filter: string): Observable<LookupValueDTO[]> {
    let params = new HttpParams();
    params = params.append("disableLoading", "true");
    if (filter) {
      params = params.append("filter", filter);
    }

    return this.http.get<LookupValueDTO[]>(this.buildUrl(`Store/LookUp`), {
      params,
    });
  }

  searchActiveStores(filter: string): Observable<LookupValueDTO[]> {
    let params = new HttpParams();
    params = params.append("disableLoading", "true");
    if (filter) {
      params = params.append("filter", filter);
    }

    return this.http.get<LookupValueDTO[]>(
      this.buildUrl(`Store/LookUp/Active`),
      {
        params,
      }
    );
  }

  searchIssuingEntities(filter: string): Observable<LookupValueDTO[]> {
    let params = new HttpParams();
    params = params.append("disableLoading", "true");

    return this.http.get<LookupValueDTO[]>(
      this.buildUrl(`IssuingEntity/LookUp/${filter}`),
      { params }
    );
  }

  searchMarkets(filter: string): Observable<LookupValueDTO[]> {
    let params = new HttpParams();
    params = params.append("disableLoading", "true");
    if (filter) {
      params = params.append("filter", filter);
    }
    return this.http.get<LookupValueDTO[]>(this.buildUrl(`Market/LookUp`), {
      params,
    });
  }

  searchAllRaces(): Observable<LookupValueDTO[]> {
    return this.lookupSearchAll("RACE");
  }
  searchTribals(filter: string): Observable<LookupValueDTO[]> {
    return this.lookupSearch("TRIBAL", filter);
  }
}
