import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { MenuService } from "../core/menu/menu.service";
import { SharedModule } from "../shared/shared.module";
import { AuthenticationModule } from "./authentication/authentication.module";
import { menu } from "./menu";
import { routes } from "./routes";

@NgModule({
  imports: [SharedModule, RouterModule.forRoot(routes), AuthenticationModule],
  declarations: [],
  exports: [RouterModule],
})
export class RoutesModule {
  constructor(public menuService: MenuService) {
    menuService.addMenu(menu);
  }
}
