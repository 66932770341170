const Home = {
  text: "Home",
  link: "/portal/home",
  icon: "bi bi-house-door",
};

const MyAccount = {
  text: "My Account",
  link: "/portal/users",
  icon: "bi bi-person",
};
const Transactions = {
  text: "Transactions",
  link: "/portal/transactions",
  icon: "bi bi-file-earmark-spreadsheet",
};

export const menu = [Home, Transactions, MyAccount];
