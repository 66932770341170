import { Injectable } from "@angular/core";
import { AbstractControl, ValidatorFn } from "@angular/forms";
import moment from "moment";
@Injectable({
  providedIn: "root",
})
export class GenericFunctionsService {
  constructor() {}

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 46 || charCode > 57)) {
      return false;
    }
    return true;
  }

  numberSymbolOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 32 || charCode > 64)) {
      return false;
    }
    return true;
  }
  greaterThan(minRequired = 0): ValidatorFn {
    return function validate(control: AbstractControl) {
      if (control.value <= minRequired) {
        return {
          min: {
            min: minRequired,
          },
        };
      }
      return null;
    };
  }
  greaterFronNowEndDateRange(): ValidatorFn {
    return function validate(control: AbstractControl) {
      const startDate = moment(control.value[0]);
      const endDate = moment(control.value[1]);
      const today = moment().startOf("day");
      if (endDate && endDate.isBefore(today)) {
        return {
          minEndDate: {
            minEndDate: today,
          },
        };
      }
      return null;
    };
  }
}
