import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { CreditCardDirectivesModule } from "angular-cc-library";
import { NgxCaptchaModule } from "ngx-captcha";
import { NgxMaskModule } from "ngx-mask";
import { NgxSelectModule } from "ngx-select-ex";
import { SharedModule } from "src/app/shared/shared.module";
import { LoginComponent } from "./login/login.component";
import { RecoverComponent } from "./recover/recover.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { SignUpComponent } from "./sign-up/sign-up.component";

const routes: Routes = [
  //{ path: '', redirectTo: 'login', pathMatch: 'full' },
  //{ path: 'login', component: LoginComponent },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    NgxSelectModule,
    CreditCardDirectivesModule,
    NgxMaskModule.forRoot(),
    NgxCaptchaModule,
    TranslateModule,
  ],
  declarations: [
    LoginComponent,
    RecoverComponent,
    ResetPasswordComponent,
    SignUpComponent,
  ],
  exports: [
    //RouterModule,
  ],
})
export class AuthenticationModule {}
