<!-- START Top Navbar-->
<nav class="navbar topnavbar" role="navigation">
  <!-- START navbar header-->
  <div class="navbar-header">
    <a class="navbar-brand" href="/portal">
      <div class="brand-logo">
        <img class="logo" src="/assets/img/logo.png" alt="image" />

        <img class="solimarket" src="/assets/img/solimarket.png" alt="image" />
      </div>
      <div class="brand-logo-collapsed">
        <img src="/assets/img/logo.png" alt="image" />
      </div>
    </a>
  </div>
  <!-- END navbar header-->
  <!-- START Left navbar-->
  <ul class="navbar-nav mr-auto flex-row">
    <li class="nav-item">
      <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
      <a
        class="nav-link d-none d-md-block d-lg-block d-xl-block"
        trigger-resize=""
        (click)="toggleCollapsedSideabar()"
        *ngIf="!isCollapsedText()"
      >
        <em class="fas fa-bars"></em>
      </a>
      <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
      <a
        class="nav-link sidebar-toggle d-md-none"
        (click)="
          settings.toggleLayoutSetting('isCollapsed');
          settings.toggleLayoutSetting('asideToggled');
          $event.stopPropagation()
        "
      >
        <em class="fas fa-bars"></em>
      </a>
    </li>
  </ul>
  <!-- END Left navbar-->
  <ul class="navbar-nav flex-row">
    <li class="nav-item">
      <a
        class="nav-link"
        [routerLink]="'/portal/users/profile'"
        title="Profile"
      >
        <em class="bi bi-person"></em>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" (click)="logout()" title="Logout">
        <em class="bi bi-box-arrow-in-left"></em>
      </a>
    </li>
  </ul>
</nav>
<!-- END Top Navbar-->
<ng-template #selectIssuingEntity let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Select Agency</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-select-issuing-entity></app-select-issuing-entity>
  </div>
</ng-template>
