<div class="wrapper">
  <div class="block-center mt-4 wd-responsive">
    <!-- START card-->
    <div class="card card-flat">
      <div class="card-header text-center bg-primary">
        <img
          class="solimarket"
          src="/assets/img/solimarket-portal.png"
          alt="image"
        />
      </div>
      <p class="title">Please create a new PIN for your account:</p>

      <div class="card-body" style="padding-top: 1em !important">
        <form [formGroup]="recoverForm" class="form-validate mb-3">
          <div class="form-group">
            <label class="m-0">Code</label>

            <div class="input-group with-focus">
              <input
                class="form-control pin"
                type="password"
                name="code"
                maxlength="6"
                formControlName="code"
                required=""
              />
            </div>
            <div
              class="text-danger"
              *ngIf="
                recoverForm.controls['code'].hasError('required') &&
                (recoverForm.controls['code'].dirty ||
                  recoverForm.controls['code'].touched)
              "
            >
              This field is required
            </div>
          </div>
          <div class="form-group">
            <label class="m-0">New PIN</label>

            <div class="input-group with-focus">
              <input
                [(ngModel)]="pin"
                class="form-control pin"
                type="password"
                name="pin"
                maxlength="4"
                formControlName="pin"
                required=""
              />
            </div>
            <div
              class="text-danger"
              *ngIf="
                recoverForm.controls['pin'].hasError('required') &&
                (recoverForm.controls['pin'].dirty ||
                  recoverForm.controls['pin'].touched)
              "
            >
              This field is required
            </div>
          </div>
          <div class="form-group">
            <label class="m-0">Confirm New PIN</label>

            <div class="input-group with-focus">
              <input
                [(ngModel)]="confirmPin"
                class="form-control pin"
                type="password"
                name="confirmPin"
                maxlength="4"
                formControlName="confirmPin"
                (input)="validateSameFourDigits()"
              />
            </div>
            <div
              class="text-danger"
              *ngIf="
                recoverForm.controls['confirmPin'].hasError('required') &&
                (recoverForm.controls['confirmPin'].dirty ||
                  recoverForm.controls['confirmPin'].touched)
              "
            >
              This field is required
            </div>
          </div>
          <div
            class="text-danger"
            *ngIf="
              confirmPin.length === 4 && pin.length === 4 && confirmPin !== pin
            "
          >
            New PINs do not match.
          </div>
          <div class="text-danger" *ngIf="sameDigits">
            The new PIN cannot be the same four digits.
          </div>
          <div class="clearfix mt-2">
            <div class="">
              <a class="forgot-pin" [routerLink]="'/auth/login'">Back Login</a>
            </div>
          </div>
          <button
            class="btn btn-block btn-primary mt-2"
            type="submit"
            [disabled]="!recoverForm.valid || !recoverForm.dirty"
            (click)="submit()"
          >
            Change PIN
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
