import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import moment from "moment";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { Pagination } from "src/app/core/dtos/pagination";
import { AuthenticationService } from "src/app/core/services/authentication.service";
import { BaseService } from "src/app/core/services/base.service";
import { JwTockensDTO } from "src/app/dtos/jwtTockensDTO";
import { UserDTO } from "src/app/dtos/userDTO";
import { UserListDTO } from "src/app/dtos/userListDTO";
import { UserPasswordUpdateDTO } from "src/app/dtos/userPasswordUpdateDTO";
import { UserUpdateDTO } from "src/app/dtos/userUpdateDTO";

@Injectable({
  providedIn: "root",
})
export class UsersService extends BaseService {
  constructor(
    protected readonly http: HttpClient,
    private authService: AuthenticationService
  ) {
    super(http);
    this.setBaseUrl("UserManagement");
  }

  getProfile() {
    return this.http.get<UserDTO>(this.buildUrl(`/GetProfile`), {});
  }

  updateProfile(entity: UserUpdateDTO): Observable<any> {
    return this.http.put(this.buildUrl(`/UpdateCurrentProfile`), entity);
  }

  changePin(pin): Observable<any> {
    return this.http.put(this.buildUrl(`/UpdateWalletPassword`), {
      password: pin,
    });
  }

  getIsVerified(id: number): Observable<any> {
    return this.http.get<any>(this.buildUrl(`/${id}/isVerified`));
  }

  resendInvitation(id: number): Observable<any> {
    return this.http.post(this.buildUrl(`/${id}/resendInvitation`), null);
  }

  activate(id: number): Observable<UserDTO> {
    return this.http.put<UserDTO>(this.buildUrl(`/${id}/Activate`), {});
  }

  deactivate(id: number): Observable<UserDTO> {
    return this.http.put<UserDTO>(this.buildUrl(`/${id}/Deactivate`), {});
  }

  changePassword(dto: UserPasswordUpdateDTO): Observable<UserDTO> {
    return this.http.put<UserDTO>(
      this.buildUrl(`/UpdateCurrentProfilePassword`),
      dto
    );
  }

  getTokens(code: string): Observable<JwTockensDTO> {
    return this.http.get<JwTockensDTO>(
      this.buildUrl(`/GetUserJwtTokens/${code}`)
    );
  }
  refreshTokens(refreshToken: string): Observable<JwTockensDTO> {
    return this.http.get<JwTockensDTO>(
      this.buildUrl(`/RefreshUserJwtTokens/${refreshToken}`)
    );
  }
}
