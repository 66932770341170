import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import Amplify, { Auth } from "aws-amplify";
import { LoaderService } from "src/app/core/loading/loader.service";
import { NotificationsService } from "src/app/core/notifications/notifications.service";
import { AuthenticationService } from "src/app/core/services/authentication.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-recover",
  templateUrl: "./recover.component.html",
  styleUrls: ["./recover.component.scss"],
})
export class RecoverComponent implements OnInit {
  recoverForm: FormGroup;
  pin: string = "";
  confirmPin: string = "";
  sameDigits: boolean = false;
  user: string;
  constructor(
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private notifications: NotificationsService,
    private readonly route: ActivatedRoute,
    private loaderService: LoaderService
  ) {
    this.route.params.subscribe((params) => {
      if (params.user) {
        this.user = params.user;
      }
    });
    Amplify.configure({
      aws_cognito_region: environment.cognitoRegion,
      aws_user_pools_id: environment.cognitoUserPoolId,
      aws_user_pools_web_client_id: environment.cognitoClientId,
      Analytics: {
        disabled: true,
      },
    });
  }

  ngOnInit() {
    this.recoverForm = this.fb.group({
      code: [
        null,
        Validators.compose([Validators.required, Validators.minLength(6)]),
      ],
      pin: [
        null,
        Validators.compose([Validators.required, Validators.minLength(4)]),
      ],
      confirmPin: [
        null,
        Validators.compose([Validators.required, Validators.minLength(4)]),
      ],
    });
  }

  submit() {
    this.loaderService.isLoading.next(true);

    Auth.forgotPasswordSubmit(
      this.user,
      this.formControls.code.value,
      "00" + this.formControls.confirmPin.value
    )
      .then((data) => {
        this.loaderService.isLoading.next(false);
        this.notifications.success("Reset Pin", "Reset pin successfully");
        this.router.navigate([`/auth/login`]);
      })
      .catch((error) => {
        this.loaderService.isLoading.next(false);
        this.notifications.error("", error.message);
      });
  }
  public get formControls(): any {
    return this.recoverForm.controls;
  }
  validateSameFourDigits() {
    if (this.confirmPin.length === 4) {
      const characters = this.confirmPin.split("");
      let same = true;
      for (let index = 0; index < characters.length - 1 && same; index++) {
        if (characters[index] !== characters[index + 1]) {
          same = false;
        }
      }
      this.sameDigits = same;
    }
  }
}
