import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BaseService } from "../services/base.service";

const swal = require("sweetalert");

@Injectable()
export class NotificationsService extends BaseService {
  constructor(protected http: HttpClient, private toaster: ToastrService) {
    super(http);
  }

  success(title: string, message: string): any {
    this.toaster.success(message, title);
  }

  error(title: string, message: string): any {
    this.toaster.error(message, title);
  }

  info(title: string, message: string): any {
    this.toaster.info(message, title);
  }

  warning(title: string, message: string): any {
    this.toaster.warning(message, title);
  }

  confirm(
    title: string,
    text: string,
    cancelText: string = "Cancel",
    okText:string = "OK",
    confirmCallback?,
    cancelCallback?
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      swal({
        title,
        text,
        icon: "",
        buttons: {
          cancel: {
            text: cancelText,
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: okText,
            value: true,
            visible: true,
            className: "bg-danger",
            closeModal: true,
          },
        },
      }).then((isConfirm) => {
        if (isConfirm) {
          if (confirmCallback) {
            confirmCallback();
          }
          resolve("");
        } else {
          if (cancelCallback) {
            cancelCallback();
          }
          reject();
        }
      });
    });
  }
}
